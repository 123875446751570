
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Action, State} from "vuex-class";
import {IBusinessSectorDB, IHeader, IMissionDB, IUserDB, LabelValue, MissionPeriod, StoreAction} from "@/types";
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import MegaTable from "@/components/MegaTable.vue";
import Visualisations from "@/components/Visualisations.vue";
import moment from "moment-timezone";
import {capitalize} from "@/helpers/commons";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import clonedeep from "lodash.clonedeep";
import {ROUTES} from "@/data";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";

@Component({
    name: "Planning",
    components: {MissionTile, Visualisations, MegaTable, ViewConfigurator}
})
export default class Planning extends mixins(PageMixin) {
    loading = false;
    timeout: any = 0;
    resizetimeout: any = 0;
    interims: any = [];
    data: any = [];
    missions: IMissionDB[] = [];
    boxes: any = [];
    visualisations: any = {
        dateRange: {
            from: moment().startOf('week').valueOf(),
            to: moment().endOf('week').valueOf(),
        },
        mode: 'week'
    }
    viewFilters: any = {
        ...this.$route.meta!.viewFilters.reduce((acc: any, filter: any) => {
            acc[filter.name] = filter.defaultValue;
            return acc;
        }, {}),
    };
    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";

    columnWidth = 0;

    pageTitle = "";
    moment = moment;

    $refs!: any;

    @Action('actions/getMissions') getMissions!: StoreAction;
    @Action('actions/getCompanyCalendar') getCompanyCalendar!: StoreAction;

    @State('selectedCollaborators') selectedCollaborators!: IUserDB[];
    @State('selectedDomains') selectedDomains!: IBusinessSectorDB[];

    @Watch('selectedCollaborators', {deep: true})
    onCollabs() {
        this.timedInit();
    }

    @Watch('selectedDomains', {deep: true})
    onDomains() {
        this.timedInit();
    }

    @Watch('viewFilters', {deep: true})
    onViewFilters() {
        this.timedInit();
    }

    @Watch('visualisations', {deep: true})
    onVisualisations() {
        this.timedInit();
    }

    @Watch('$route.query.mode')
    onMode(n:string, o:string) {
        if (n) {
            // @ts-ignore;
            this.pageTitle = <string>this.$t('page.planning.titles')[this.$route.query.mode];

        }
        if (o && !n) {
            this.visualisations.mode = "week";
            this.timedInit();
        }
    }

    get queryMode() {
        return this.$route.query.mode;
    }

    get isQueryGantt() {
        return this.queryMode === "gantt-workers" || this.queryMode === "gantt-clients";
    }

    get isGantSubview() {
        return this.isGanttWorkers || this.isGanttClients;
    }

    get isComingInterimsSubview() {
        return this.visualisations.mode === "comingInterims";
    }

    get isWeekSubview() {
        return this.visualisations.mode === "week";
    }

    get headers(): IHeader[] {
        const fromDate = moment(this.ranges.from);
        let headers: any = [];
        let i = 0, format;

        if (this.isWeekSubview || this.isGantSubview || this.isComingInterimsSubview) {
            format = "dddd";
            while (i < 7) {
                const date = fromDate.clone().add(i, 'day');

                headers.push({
                    label: capitalize(date.format(format)),
                    sup: date.format('D'),
                    value: date.valueOf()
                });

                ++i;
            }

            if (this.isGantSubview) {
                headers.splice(0, 0, {
                    label: this.isGanttWorkers ? <string>this.$t('page.clients.topbar.interims') : <string>this.$t('page.clients.topbar.clients'),
                    value: '_interims',
                    filterable: false
                });
            }
        } else {
            headers = moment.weekdays(true).map((d: string) => ({label: capitalize(d)}));
        }

        return headers;
    }

    get rows() {
        const rows: any = [];

        if (this.missions) {
            if (this.isWeekSubview || this.isGantSubview || this.isComingInterimsSubview) {
                if (this.isGantSubview) {
                    this.data.forEach((i: any) => {
                        rows.push(i);
                    });
                } else {
                    if (this.isComingInterimsSubview) {
                        this.headers.forEach((header: IHeader, index: number) => {
                            let row: any = [];

                            this.interims.forEach((interim: any) => {
                                const missionStart:any = moment(interim.missionStart).startOf('day').valueOf();
                                const headerStart:any = moment(header.value).startOf('day').valueOf();

                                console.log(missionStart, headerStart, moment(interim.missionStart).format('DD/MM/YYYY'), moment(header.value).format('DD/MM/YYYY'))
                                if (missionStart === headerStart) {
                                    row.push(interim);
                                }
                            });

                            rows.push(row);
                        });
                    } else {
                        this.headers.forEach((header: IHeader, index: number) => {
                            let row: any = [];

                            this.missions.forEach((mission: IMissionDB) => {
                                if (mission.periods?.length && this.dayIsInRange(header.value, mission.periods)) {
                                    row.push(mission);
                                }
                            });

                            rows.push(row);
                        });
                    }
                }
            } else {
                const fromDate = moment(this.ranges.from);
                let nb = fromDate.daysInMonth();
                let i = 0;
                let rowsIndex = 0;
                let firstDay;
                let firstDateDay = fromDate.weekday();

                if (firstDateDay !== 0) {
                    firstDay = fromDate.clone().subtract(firstDateDay, 'day');
                    nb += firstDateDay;
                } else {
                    firstDay = fromDate;
                }

                while (i < nb) {
                    const cellDate: any = firstDay.clone().add(i, 'day');
                    const foundMissions: any = [];

                    if (!rows[rowsIndex]) {
                        rows[rowsIndex] = [];
                    }

                    if (cellDate.month() === fromDate.month()) {
                        this.missions.forEach((mission: IMissionDB) => {
                            if (mission.periods?.length && this.dayIsInRange(cellDate.valueOf(), mission.periods)) {
                                foundMissions.push(mission);
                            }
                        });
                    }

                    if (this.isComingInterimsSubview) {
                        rows[rowsIndex].push({
                            timestamp: cellDate.valueOf(),
                            missions: foundMissions,
                            out: firstDateDay > 0
                        });
                    } else {
                        rows[rowsIndex].push({
                            timestamp: cellDate.valueOf(),
                            missions: foundMissions,
                            out: firstDateDay > 0
                        });
                    }

                    --firstDateDay;

                    if (++i % 7 === 0) {
                        rowsIndex += 1;
                    }

                    if (i === nb && rows[rowsIndex]) {
                        const rest = 7 - rows[rowsIndex].length;

                        if (rest) {
                            let j = 0;
                            while (j < rest) {
                                const cellDate: any = firstDay.clone().add(i + j, 'day');
                                rows[rowsIndex].push({
                                    timestamp: cellDate.valueOf(),
                                    missions: [],
                                    out: true
                                })
                                ++j;
                            }
                        }
                    }
                }

            }
        }

        return rows;
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === 'number') {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        } else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate
        }
    }

    get isGanttWorkers() {
        return this.visualisations.mode === "gantt-workers";
    }

    get isGanttClients() {
        return this.visualisations.mode === "gantt-clients";
    }

    get formattedViewFilters() {
        return {
            search: this.viewFilters.search,
            job: (this.viewFilters.job?.length && this.viewFilters.job[0].value) || undefined,
            client: this.viewFilters.client || undefined,
            businessSector: (this.viewFilters.businessSector?.length && this.viewFilters.businessSector.map((j: LabelValue) => j.value)) || undefined,
            jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
            withAlerts: this.viewFilters.hasVehicle === 'yes' ? true : this.viewFilters.hasVehicle === 'no' ? false : undefined,
            missionStatus: this.viewFilters.missionStatus?.length && this.viewFilters.missionStatus[0].value || undefined
        }
    }

    registerView(modelName: string) {
        this.$emit('registerView', {
            modelName,
            filters: this.formattedViewFilters,
            mode: this.visualisations.mode
        });
    }

    eraseView(selectedView: any) {
        this.$emit('eraseView', {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: this.formattedViewFilters,
            mode: this.visualisations.mode
        });
    }

    isToday(timestamp: number) {
        const date = moment(timestamp);
        const today = moment();

        return date.date() === today.date()
            && date.month() === today.month()
            && date.year() === today.year()
    }

    dayIsInRange(timestamp: number, periods: MissionPeriod[]) {
        const min: any = moment(this.periodMin(periods)).startOf('day');
        // const max:any = moment(this.periodMax(periods)).endOf('day');
        const toTest = moment(timestamp);

        return toTest.date() === min.date()
            && toTest.month() === min.month()
            && toTest.year() === min.year();
    }

    periodMin(periods: MissionPeriod[]) {
        let min = Infinity;

        if (periods && periods.length) {
            periods.forEach((period: MissionPeriod) => {
                if (period.start < min) {
                    min = period.start;
                }
            });
        }

        return min;
    }

    periodMax(periods: MissionPeriod[]) {
        let max = -Infinity;

        if (periods && periods.length) {
            periods.forEach((period: MissionPeriod) => {
                if (period.end > max) {
                    max = period.end;
                }
            });
        }

        return max;
    }

    timedInit() {
        this.loading = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    sortMissions() {
        this.missions.sort((a: any, b: any) => {
            if (this.periodMin(a.periods) > this.periodMin(b)) {
                return 1;
            }
            if (this.periodMin(a.periods) < this.periodMin(b)) {
                return -1;
            }
            return 0;
        })
    }

    calculateMissions() {
        this.boxes = [];
        const data = clonedeep(this.data);
        this.data.forEach((i: any) => {
            i.missions = [];
        });
        setTimeout(() => {
            if (this.$refs?.table?.$refs?.columns?.$el) {
                this.columnWidth = this.$refs.table.$refs.columns.$el.scrollWidth / 8; // 8 columns
            }

            setTimeout(() => {
                this.data = data.map((item: any) => {
                    let sortedData:any = [];
                    item.missions?.forEach((mission: any, index:number) => {
                        let finalFrom, finalTo;

                        if (mission.from > this.ranges.to
                            || mission.to < this.ranges.from) {
                            return;
                        }
                        if (mission.from < this.ranges.from) {
                            mission._before = true;
                            finalFrom = this.ranges.from;
                        } else {
                            finalFrom = mission.from;
                        }
                        if (mission.to > this.ranges.to) {
                            mission._after = true;
                            finalTo = this.ranges.to;
                        } else {
                            finalTo = mission.to;
                        }

                        let diffDay: number = (moment(finalTo).diff(moment(finalFrom), 'day') + 1);

                        mission._left = (moment(finalFrom).weekday() * this.columnWidth);
                        mission._width = (diffDay * this.columnWidth) - 4;

                        if (mission._left + mission._width > this.columnWidth * 7) {
                            mission._width = this.columnWidth * 7 - mission._left - 4;
                        }

                        mission._left += 'px';
                        mission._width += 'px';

                        mission.finalFrom = finalFrom;
                        mission.finalTo = finalTo;

                        if (!sortedData.length) {
                            sortedData.push([mission]);
                        } else {
                            let found = false;

                            for (let i = 0, len = sortedData.length; i < len; ++i) {
                                for (let j = 0, len2 = sortedData[i].length; j < len2; ++j) {
                                    const m = sortedData[i][j];
                                    const mFrom = moment(m.finalFrom).startOf('day');
                                    const mTo = moment(m.finalTo).endOf('day');
                                    const iFrom = moment(finalFrom).startOf('day');
                                    const iTo = moment(finalTo).endOf('day');
                                    const range = moment.range(mFrom, mTo);

                                    found = !range.contains(iFrom) && !range.contains(iTo);
                                }
                                if (found) {
                                    sortedData[i].push(mission);
                                    break;
                                }
                            }
                            if (!found) {
                                sortedData.push([mission]);
                            }
                        }
                    });
                    item.sortedMissions = sortedData;

                    return item;
                });
                this.$forceUpdate();
                this.$nextTick(() => {
                    if (this.$refs?.table?.$refs?.columns?.$el) {
                        (this.$refs.table.$refs.columns.$el.querySelectorAll('.column') || []).forEach((el: any) => el.style.height = '100%');
                        this.$nextTick(() => {
                            if (this.$refs?.table?.$refs?.columns?.$el) {
                                let height = this.$refs.table.$refs.columns.$el.scrollHeight + 'px';

                                (this.$refs.table.$refs.columns.$el.querySelectorAll('.column') || []).forEach((el: any) => el.style.height = height);
                            }
                        })
                    }
                })
            }, 200);
        });
        this.$forceUpdate();
    }

    onResize() {
        if (this.isGantSubview) {
            if (this.resizetimeout) {
                clearTimeout(this.resizetimeout);
            }

            this.resizetimeout = setTimeout(() => {
                this.calculateMissions();
            }, 200);
        }
    }

    onClientToggle() {
        setTimeout(() => {
            this.$forceUpdate();
            this.$nextTick(() => {
                this.$refs.table.calculateOffset()
            })
        }, 300);
    }

    goTo(row: any) {
        if (this.visualisations.mode === "gantt-workers") {
            this.$router.push({name: ROUTES.APP.INTERIM, params: {interimId: row._id}});
        } else {
            this.$router.push({name: ROUTES.APP.CLIENT, params: {companyId: row._id}});
        }
    }

    missionChanged(changedMission: IMissionDB) {
        this.timedInit();
    }

    async init() {
        this.loading = true;
        try {
            const data: any = {...this.ranges, ...this.formattedViewFilters};

            if (this.isComingInterimsSubview) {
                this.interims = await this.getCompanyCalendar(data);
            } else {
                if (this.isGantSubview) {
                    data.subview = this.visualisations.mode;
                    this.data = await this.getMissions(data);
                    this.calculateMissions();
                } else {
                    this.missions = await this.getMissions(data);
                }
            }

            this.sortMissions();
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    created() {
        if (this.isQueryGantt) {
            this.visualisations.mode = this.queryMode;
        } else {
            this.visualisations.mode = "week";
        }
        window.addEventListener("resize", () => {
            this.onResize();
        });
    }

    destroyed() {
        window.removeEventListener("resize", () => {
            this.onResize();
        });
    }

    beforeMount() {
        if (this.$route.query.mode) {
            // @ts-ignore;
            this.pageTitle = <string>this.$t('page.planning.titles')[this.$route.query.mode];
        }
        this.timedInit();
    }
}
